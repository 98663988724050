<template>
    <div id="app">
        <layout v-if="$route.path.indexOf('/login') === -1"/>
        <router-view></router-view>
    </div>
</template>

<script>
import layout from './components/layout/index.vue'
export default {
    name: 'App',
    components: {
        layout
    },
    created(){
        console.log(this.$route)
    }
}
</script>

<style lang="scss" scoped>

</style>
