export default {
    menuSwitch: true,
    historyRecord: [
        {
            path: '/questionEdit',
            alwaysShow: true,
            meta: { title: '题目编辑', icon: 'el-icon-tickets'},
            component: () => import('../views/questionEdit')
        },
    ],
    userInfo: {},
    menuList: [],
    isLogin: false
}