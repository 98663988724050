import Vue from 'vue'
import VueRouter from 'vue-router'

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
  return routerPush.call(this, location).catch(err => {})
};


Vue.use(VueRouter)

/* Layout */
import Layout from './../components/layout'

export const constantRoutes = [
  {
    path: '/',
    redirect: '/login',
    hidden: true,
  },
  {
    path: '/login',
    name: 'login',
    hidden: true,
    meta: { title: 'login', icon: 'login', affix: false },
    component: () => import('../views/login.vue')
  },
  {
    path: '/questionEdit',
    name: 'questionEdit',
    hidden: true,
    meta: { title: '题目编辑', icon: 'questionEdit', affix: false },
    component: () => import('../views/questionEdit/index.vue')
  },
  
]

const router = new VueRouter({
  mode: 'hash', // require service support
  // base: '',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

// router.beforeEach((to, from, next) => {
//   next()
// })

export default router
